<template>
  <div class="qrcode">
    <div id="reader"></div>
  </div>
</template>

<script>
import {
  Html5Qrcode,
  Html5QrcodeScanType,
  Html5QrcodeSupportedFormats,
} from "html5-qrcode";
export default {
  name: "scanQrCodes",
  components: {},
  data() {
    return {
      qrCode: null,
      url: "",
    };
  },
  created() {
    const { url } = this.$route.query;
    if (url != undefined) {
      this.url = url;
    }
    this.getCameras();
  },
  methods: {
    //获取摄像头设备
    getCameras() {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            this.qrCode = new Html5Qrcode("reader");
            this.start(); //开始扫码
          }
        })
        .catch((error) => {
          this.$toast(error);
        });
    },
    //扫码成功回调
    onScanSuccess(decodedText, decodedResult) {
      if (decodedText) {
        this.$emit("success", decodedText);
        this.$router.replace({
          name: this.url,
          query: {
            result: encodeURIComponent(JSON.stringify(decodedText)),
          },
        });
        this.qrCode.clear(); //扫码后停止扫描
      }
    },
    //扫码失败回调
    onScanError(errorMessage) {
      console.log("暂无额扫描结果", errorMessage);
      this.$emit("error", errorMessage);
    },
    //开始扫描
    start() {
      this.qrCode
        .start(
          {
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
            facingMode: { ideal: "environment" },
            focusMode: { ideal: "continuous" },
          },
          {
            fps: 2, //500毫秒扫描一次
            qrbox: { width: 250, height: 250 },
            focusMode: "continuous",
            facingMode: "environment",
            // aspectRatio: 1.777778, //宽高比呈现视频源
            formatsToSupport: [
              Html5QrcodeSupportedFormats.QR_CODE, //二维码
              Html5QrcodeSupportedFormats.UPC_A, //条形码
              Html5QrcodeSupportedFormats.UPC_E, //条形码
              Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION, //条形码
            ],
            advanced: [{ zoom: 2.0 }],
            rememberLastUsedCamera: true, //如果用户之前已授予权限 - 用户界面中的请求权限选项将被跳过，最后选择的相机将自动启动进行扫描。
            experimentalFeatures: {
              useBarCodeDetectorIfSupported: true,
            },
            defaultZoomValueIfSupported: 2,
            willReadFrequently: true,
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], //限制扫描是用Camera或File基于扫描的支持。
            videoConstraints: {
              aspectRatio: 2, // <-- this would be honoured
              width: { min: 640, ideal: 1280, max: 1920 },
              height: { min: 480, ideal: 720, max: 1080 },
              facingMode: { ideal: "environment" },
              focusMode: { ideal: "continuous" },
              advanced: [{ zoom: 2.0 }],
            },
            autofocus: true, //自动对焦  但使用的时候没有体会到
            colorDark: "#0000ff", //加深二维码黑色部分的颜色提高识别度
            colorLight: "#ffffff", //这个应该是提高非黑即白部分的亮度  提高识别度
            visualFeedback: true, //开启视觉反馈
            halfSample: true, //缩小二维码提高识别精度吧
          },
          (decodedText, decodedResult) => {
            this.onScanSuccess(decodedText, decodedResult);
          },
          (errorMessage) => {
            this.onScanError(errorMessage);
          }
        )
        .catch((err) => {
        
        });
    },
    //停止扫码
    stop() {
      this.qrCode
        .stop()
        .then((ignore) => {
          console.log(ignore, "QR Code scanning stopped.");
        })
        .catch((err) => {
          console.log(err, "Unable to stop scanning.");
        });
    },
  },
  beforeDestroy() {
    this.stop();
  },
};
</script>

<style lang="less" scoped>
.qrcode {
  position: fixed;
  top: 110px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #000;
  z-index: 99999;
}
#reader {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
</style>
